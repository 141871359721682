import axios from "axios";
export function queryProjectOverview(memberUrl,params) { 
    return axios.post(memberUrl + "/biz/reportFrom/purchase/queryProjectOverview.html", params)
}


export function queryProjectInfoByBidType(memberUrl,params) { 
    return axios.post(memberUrl + "/biz/reportFrom/purchase/queryProjectInfoByBidType.html", params)
}

// 月度招采统计
export function queryYearProjectInfo(memberUrl,params) { 
    return axios.post(memberUrl + "/biz/reportFrom/purchase/queryYearProjectInfo.html", params)
}

// 查询招标供应商项目信息
export function queryConfirmedProjectInfo(memberUrl,params) { 
    return axios.post(memberUrl + "/biz/reportFrom/purchase/queryConfirmedProjectInfo.html", params)
}

// 项目查询招标供应商信息
export function querySupplierQuoteInfo(memberUrl,params) { 
    return axios.post(memberUrl + "/biz/reportFrom/purchase/querySupplierQuoteInfo.html", params)
}