<template>
    <el-form ref="form" :model="form" label-width="80px" class="report-procurement">
        <div class="row01">
            <div class="row01-line01">
                <div style="flex: 2;">
                    <div class="grid-content bg-purple">
                        <el-card class="box-card">
                            <div slot="header" class="clearfix">
                                <span>项目总览</span>
                                <el-date-picker size="mini" style="float:right" v-model="placeholderTime"
                                    @change="xmzlChange" type="datetimerange" range-separator="至"
                                    start-placeholder="开始日期" end-placeholder="结束日期">
                                </el-date-picker>
                            </div>
                            <div v-show="!isLoadingzl" class="text item" style="display:flex;flex-wrap: wrap;">
                                <div v-for="(item, index) in project" :key="index" class="report-procurement-pro-card"
                                    :style="{ backgroundColor: colorRgb(item.bcolor) }">
                                    <div class="report-procurement-icon" :style="{ backgroundColor: item.bcolor }"><i
                                            :class="item.icon" /></div>
                                    <div>
                                        <div><span style="font-weight: 600;font-size: 18px;">{{ item.value
                                        }}</span><span style="font-size:12px;color:#909399">{{ item.nuit
}}</span>
                                        </div>
                                        <div>{{ item.name }}</div>
                                    </div>
                                </div>
                            </div>
                            <el-skeleton v-show="isLoadingzl" :rows="2" style="height:100%" />
                        </el-card>
                    </div>
                </div>
                <div style="flex: 3;" class="report-procurement-detail">
                    <div class="grid-content bg-purple">
                        <el-card class="box-card">
                            <div slot="header" class="clearfix">
                                <el-tabs v-model="activeName" @tab-click="tabsChangge">
                                    <el-tab-pane label="物资" name="10" />
                                    <el-tab-pane label="工程" name="20" />
                                    <el-tab-pane label="服务" name="30" />
                                </el-tabs>
                            </div>
                            <div v-show="!isLoadingzj" style="display:flex;">
                                <div v-for="(item, index) in detail" :key="index"
                                    class="report-procurement-detail-card">
                                    <div class="report-detail-card-head"
                                        :style="{ backgroundColor: colorRgb(item.bcolor) }">{{ item.name }}</div>
                                    <div class="report-detail-card-body"
                                        :style="{ backgroundColor: colorRgb(item.bcolor) }">
                                        <span style="font-weight: 600;font-size: 20px;">{{ item.value }}</span><span
                                            style="font-size:12px;">{{ item.nuit }}</span>
                                    </div>
                                </div>
                            </div>
                            <el-skeleton v-show="isLoadingzj" :rows="3" style="height:100%" />
                        </el-card>
                    </div>
                </div>
            </div>

            <div class="row01-line02">
                <div class="grid-content bg-purple">
                    <div class="grid-content bg-purple">
                        <el-card class="box-card">
                            <div slot="header" class="clearfix">
                                <span>月度招采统计</span>
                                <el-date-picker style="float:right" size="mini" v-model="ydzctjValue" type="year"
                                    @change="ydzctjChange" placeholder="选择年">
                                </el-date-picker>
                            </div>
                            <div v-show="!isLoadingzc" class="text item" style="display:flex;flex-wrap: wrap;">
                                <div id="ydzctjEcharts" style="width: 864px;height:374px"></div>
                            </div>
                            <el-skeleton v-show="isLoadingzc" :rows="9" style="height:100%" />
                        </el-card>
                    </div>
                </div>
            </div>
        </div>
        <div class="row02">
            <div class="row02-line01">
                <div class="procurement-table-body01">
                    <div class="grid-content bg-purple report-procurement-table">
                        <el-card class="box-card">
                            <div slot="header" class="clearfix">
                                <span>投标供应商</span>
                                <div @click="isClickProject = true;" class="project_c_l_right">
                                    <TooltipOver :content="gridForm.tableRadioName" class="project-c-title-right"
                                        refName="tooltipOver" />
                                    <i class="el-icon-arrow-down" />
                                </div>
                            </div>
                            <div style="height:100%;">
                                <el-table :data="tableData" height="100%" style="width: 100%">
                                    <el-table-column prop="supplierCpName" label="供应商名称">
                                    </el-table-column>
                                    <el-table-column prop="bkbPrice" label="报价" width="80" align="center">
                                    </el-table-column>
                                    <el-table-column prop="awardFlag" label="是否中标" width="80" align="center">
                                        <template slot-scope="scope">{{ !tableAwardFlag?"进行中":( scope.row.awardFlag == '1' ? "是" : "否")
                                            }}</template>
                                    </el-table-column>
                                </el-table>
                                <!-- <el-empty :image-size="200" style="width: 100%;height:100%"></el-empty> -->
                            </div>
                        </el-card>
                    </div>
                </div>
                <div class="procurement-table-body02">
                    <div class="grid-content bg-purple report-procurement-table">
                        <el-card class="box-card">
                            <div slot="header" class="clearfix">
                                <span>物资成交榜</span>
                            </div>
                            <div class="text item">
                                <!-- <el-table :data="materials" height="250" style="width: 100%">
                                        <el-table-column prop="name" label="物资名称" align="center">
                                        </el-table-column>
                                        <el-table-column prop="money" label="预算金额" width="80" align="center">
                                        </el-table-column>
                                        <el-table-column prop="price" label="成交均价" width="80" align="center">
                                        </el-table-column>
                                        <el-table-column prop="number" label="成交量" align="center">
                                        </el-table-column>
                                        <el-table-column prop="limit" label="成交额" width="80" align="center">
                                        </el-table-column>
                                        <el-table-column prop="reduceCosts" label="降本额" width="80" align="center">
                                        </el-table-column>
                                    </el-table> -->
                                <el-empty :image-size="200" style="width: 100%;height:100%"></el-empty>
                            </div>
                        </el-card>
                    </div>
                </div>
            </div>
            <div class="row02-line02">
                <div class="grid-content bg-purple">
                    <div class="grid-content bg-purple">
                        <el-card class="box-card">
                            <div slot="header" class="clearfix">
                                <span>采购物资价格趋势</span>
                                <div style="float:right;display:flex;">
                                    <el-date-picker size="mini" v-model="value3" type="year" placeholder="选择年">
                                    </el-date-picker>
                                    <el-input v-model="input" size="mini" placeholder="请输入物资">
                                    </el-input>
                                </div>
                            </div>
                            <div class="text item">
                                <!-- <div id="cgwzjgqsEcharts" style="width: 864px;height:374px"></div> -->
                                <el-empty :image-size="200" style="width: 864px;height:374px"></el-empty>
                            </div>
                        </el-card>
                    </div>
                </div>
            </div>
        </div>
        <el-dialog :show-close="false" width="1000px" :visible.sync="isClickProject">
            <div class="dialog-l-project">
                <el-form :inline="true" :model="formInline" class="demo-form-inline">
                    <el-form-item label="项目编号">
                        <el-input v-model="formInline.packageCode" placeholder="项目编号" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="项目名称">
                        <el-input v-model="formInline.packageName" placeholder="项目名称" clearable></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="onSubmit">查询</el-button>
                    </el-form-item>
                </el-form>
                <el-table :data="gridForm.data">
                    <el-table-column width="35">
                        <template slot-scope="scope">
                            <el-radio v-model="gridForm.tableRadio" :label="scope.row.id"
                                @input="getSupplierInfo(scope.row.id, scope.row.packageName)" />
                        </template>
                    </el-table-column>
                    <el-table-column type="index" width="55" />
                    <el-table-column property="packageCode" label="项目编号" width="200px"></el-table-column>
                    <el-table-column property="packageName" label="项目名称"></el-table-column>
                    <!-- <el-table-column property="packageStatus" label="项目状态"></el-table-column> -->
                </el-table>
                <el-pagination style="display: flex;justify-content: end;" @size-change="handleSizeChange"
                    @current-change="handleCurrentChange" background :current-page="gridForm.pageNum"
                    :page-sizes="[10, 20, 30]" :page-size="gridForm.pageSize"
                    layout="total, sizes, prev, pager, next, jumper" :total="gridForm.total">
                </el-pagination>
            </div>
        </el-dialog>
    </el-form>
</template>

<script>
import { nextTick } from 'vue';
import { queryProjectOverview, queryProjectInfoByBidType, queryYearProjectInfo, queryConfirmedProjectInfo, querySupplierQuoteInfo } from "@/api/report/procurement";
import moment from 'moment';
import TooltipOver from "./TooltipOver"
export default {
    components: { TooltipOver },
    data() {
        return {
            formInline: {
                packageName: '',
                packageCode: ''
            },
            gridForm: {
                data: [],
                tableRadio: '',
                tableRadioName: '',
                pageNum: 1,
                pageSize: 5,
                total: 0
            },
            isClickProject: false,
            isLoadingzc: false,
            isLoadingzj: false,
            isLoadingzl: false,
            tableAwardFlag: false,
            input: '',
            value3: '',
            ydzctjValue: "",
            placeholderTime: [],
            materials: [{
                name: "螺纹钢",
                money: "1.5亿",
                price: "4000",
                number: "2万",
                limit: "1.2亿",
                reduceCosts: "9.3亿",
            }, {
                name: "螺纹钢",
                money: "1.5亿",
                price: "4000",
                number: "2万",
                limit: "1.2亿",
                reduceCosts: "9.3亿",
            }, {
                name: "螺纹钢",
                money: "1.5亿",
                price: "4000",
                number: "2万",
                limit: "1.2亿",
                reduceCosts: "9.3亿",
            }, {
                name: "螺纹钢",
                money: "1.5亿",
                price: "4000",
                number: "2万",
                limit: "1.2亿",
                reduceCosts: "9.3亿",
            }, {
                name: "螺纹钢",
                money: "1.5亿",
                price: "4000",
                number: "2万",
                limit: "1.2亿",
                reduceCosts: "9.3亿",
            }, {
                name: "螺纹钢",
                money: "1.5亿",
                price: "4000",
                number: "2万",
                limit: "1.2亿",
                reduceCosts: "9.3亿",
            }, {
                name: "螺纹钢",
                money: "1.5亿",
                price: "4000",
                number: "2万",
                limit: "1.2亿",
                reduceCosts: "9.3亿",
            },
            ],
            tableData: [],
            myChart: null,
            project: [
                { name: "发布项目", value: 180, nuit: "个", icon: 'el-icon-tickets', bcolor: '#E6A23C' },
                { name: "成交项目", value: 160, nuit: "个", icon: 'el-icon-present', bcolor: '#F56C6C' },
                { name: "预算总金额", value: 500, nuit: "万元", icon: 'el-icon-coin', bcolor: '#8dc63f' },
                { name: "成交总金额", value: 400, nuit: "万元", icon: 'el-icon-aim', bcolor: '#6739b6' },
                { name: "降成本", value: 100, nuit: "万元", icon: 'el-icon-sold-out', bcolor: '#8799a3' }
            ],
            detail: [
                { name: "预算金额", value: 300, nuit: "万元", bcolor: '#E6A23C' },
                { name: "成交金额", value: 260, nuit: "万元", bcolor: '#F56C6C' },
                { name: "降成本", value: 40, nuit: "万元", bcolor: '#8dc63f' },
                { name: "进行中", value: 25, nuit: "个", bcolor: '#6739b6' },
                { name: "已完结", value: 120, nuit: "个", bcolor: '#8799a3' },
                { name: "已流标", value: 0, nuit: "个", bcolor: '#1cbbb4' },
                { name: "参与供应商", value: 53, nuit: "个", bcolor: '#9c26b0' },
            ],
            activeName: '10',
            form: {

            }
        }
    },
    watch: {
        "isClickProject": function (n, o) {
            if (!n && (this.formInline.packageName != '' || this.formInline.packageCode != '')) {
                this.formInline = {
                    packageName: '',
                    packageCode: ''
                }
                this.getProjectDetail(this.gridForm.pageNum, this.gridForm.pageSize);
            }
        }

    },
    created() {
        this.getProjectOverviewData()
        this.getProjectInfoByBidTypeData(10);
        this.getYearProjectInfo()
        this.getInitProjectInfo(this.gridForm.pageNum, this.gridForm.pageSize)
        console.log(this.$route.query.agentId)

        this.$nextTick(() => {
            // 基于准备好的dom，初始化echarts实例
            // var myChart2 = this.$echarts.init(document.getElementById('cgwzjgqsEcharts'));
            // 指定图表的配置项和数据

            // 使用刚指定的配置项和数据显示图表。

            let data = [1600, 2400, 2800, 3200, 3600, 4600, 4000, 3800, 3600, 3400, 3500, 3600];
            let olddata = [1500, 2500, 2700, 3400, 3600, 4500, 4001, 3805, 3609, 3500, 3600, 3100];
            let options2 = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999'
                        }
                    },
                    formatter: function (params) {
                        let str = '';
                        params.forEach((item, idx) => {
                            str += `${item.marker} ${item.data}<br/>`;
                            switch (idx) {
                                case 0:
                                    // 今年第一月
                                    if (item.dataIndex == 0) {
                                        // 和去年最后一月相比
                                        if (item.data - olddata[olddata.length - 1] < 0) {
                                            str += `同比:<span style="color:#F56C6C;">-${((olddata[olddata.length - 1] - item.data) / item.data * 100).toFixed(2)}%</span><br/>`;
                                        } else if (item.data - olddata[olddata.length - 1] == 0) {
                                            str += '同比:0%<br/>';
                                        } else {
                                            str += `同比:<span style="color:#67C23A;">+${((item.data - olddata[olddata.length - 1]) / item.data * 100).toFixed(2)}%</span><br/>`;
                                        }
                                    } else {
                                        // 和上一月相比
                                        if (item.data - data[item.dataIndex - 1] < 0) {
                                            str += `同比:<span style="color:#F56C6C;">-${((data[item.dataIndex - 1] - item.data) / item.data * 100).toFixed(2)}%</span><br/>`;
                                        } else if (item.data - data[item.dataIndex - 1] == 0) {
                                            str += '同比:0%<br/>';
                                        } else {
                                            str += `同比:<span style="color:#67C23A;">+${((item.data - data[item.dataIndex - 1]) / item.data * 100).toFixed(2)}%</span><br/>`;
                                        }
                                    }
                                    // 和去年相比
                                    if (item.data - olddata[item.dataIndex] < 0) {
                                        str += `同比:<span style="color:#F56C6C;">-${((olddata[item.dataIndex] - item.data) / item.data * 100).toFixed(2)}%</span><br/>`;
                                    } else if (item.data - olddata[item.dataIndex] == 0) {
                                        str += '同比:0%<br/>';
                                    } else {
                                        str += `同比:<span style="color:#67C23A;">+${((item.data - olddata[item.dataIndex]) / item.data * 100).toFixed(2)}%</span><br/>`;
                                    }
                                    break;
                                default:
                                // str += 'w(ﾟДﾟ)w';
                            }
                            str += idx === params.length - 1 ? '' : '<br/>';
                        });
                        return str;
                    }
                },
                xAxis: {
                    type: 'category',
                    data: ['1 ', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
                    axisLabel: {
                        textStyle: {
                            color: '#303133'
                        }
                    }
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        textStyle: {
                            color: '#303133'
                        }
                    }
                },
                series: [
                    {
                        data: data,
                        type: 'line',
                        symbol: 'none'
                    },
                    {
                        data: olddata,
                        type: 'line',
                        symbol: 'none'
                    }
                ]
            }
            // myChart2.setOption(options2);
        })
    },
    methods: {
        ydzctjChange(e) {
            this.getYearProjectInfo(moment(e.getTime()).format("YYYY"))
        },
        onSubmit() {
            this.getProjectDetail(this.gridForm.pageNum, this.gridForm.pageSize);
        },
        handleSizeChange(val) {
            this.getProjectDetail(this.gridForm.pageNum, val);
        },
        handleCurrentChange(val) {
            this.getProjectDetail(val, this.gridForm.pageSize);
        },
        // 查询招标供应商项目信息
        async getProjectDetail(pageNo = 1, pageSize = 10, init = false) {
            let that = this;
            let query = { agentId: this.$route.query.agentId };
            query.pageNo = pageNo;
            query.pageSize = pageSize;
            query.packageName = this.formInline.packageName;
            query.packageCode = this.formInline.packageCode;
            await queryConfirmedProjectInfo(this.member, query).then(res => {
                if (res.status == 200) {
                    that.gridForm.data = res.data.bidPackages;
                    that.gridForm.total = res.data.pageInfo.total;
                    if (init) {
                        that.gridForm.tableRadio = res.data.bidPackages[0].id;
                        that.gridForm.tableRadioName = res.data.bidPackages[0].packageName;
                    }
                }
            })
        },
        // 查询投标供应商
        async getSupplierInfo(packageId, packageName) {
            let that = this;
            await querySupplierQuoteInfo(this.member, { packageId: packageId }).then(res => {
                if (res.status == 200) {
                    that.tableAwardFlag= res.data.bidBidders.map((item)=>item.awardFlag).includes('1');
                    that.tableData = res.data.bidBidders;
                    that.gridForm.tableRadioName = packageName;
                    that.isClickProject = false;
                }
            })
        },
        // 初始化项目信息
        async getInitProjectInfo(pageNo = 1, pageSize = 10) {
            await this.getProjectDetail(pageNo, pageSize, true);
            await this.getSupplierInfo(this.gridForm.tableRadio, this.gridForm.tableRadioName);
        },
        // 月度招采统计
        getYearProjectInfo(year) {
            let that = this;
            let query = { agentId: this.$route.query.agentId };
            if (year) {
                query.year = year;
            }
            this.isLoadingzc = true;
            queryYearProjectInfo(this.member, query).then(res => {
                this.isLoadingzc = false;
                if (res.status == 200) {
                    // 成交额
                    let data1 = []
                    // 招采项目数
                    let data2 = []
                    for (const key in res.data.yearAward) {
                        const element = res.data.yearAward[key];
                        data1.push(element)
                    }

                    for (const key in res.data.yearCount) {
                        const element = res.data.yearCount[key];
                        data2.push(element)
                    }

                    // 月度招采统计
                    var myChart = this.$echarts.init(document.getElementById('ydzctjEcharts'));
                    // 指定图表的配置项和数据
                    var options = {
                        legend: {
                            data: ['成交额', '招采项目数'],
                            textStyle: {
                                color: '#303133'
                            }
                        },
                        tooltip: {
                            trigger: 'axis',
                            axisPointer: {
                                type: 'cross',
                                crossStyle: {
                                    color: '#999'
                                }
                            }
                        },
                        xAxis: [
                            {
                                type: 'category',
                                data: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
                                axisPointer: {
                                    type: 'shadow'
                                },
                                axisLabel: {
                                    textStyle: {
                                        color: '#303133'
                                    }
                                }
                            }
                        ],
                        yAxis: [
                            {
                                type: 'value',
                                name: '',
                                // min: 0,
                                // max: 5000,
                                // interval: 500,
                                axisLabel: {
                                    //y轴文字的配置
                                    textStyle: {
                                        color: '#303133'
                                    },
                                    formatter: function (value, index) {
                                        return that.setAxisLabel(value);
                                    }
                                }
                            },
                            {
                                type: 'value',
                                name: '',
                                // min: 0,
                                // max: 50,
                                // interval: 5,
                                axisLabel: {
                                    //y轴文字的配置
                                    textStyle: {
                                        color: '#303133'
                                    }
                                }
                            }
                        ],
                        series: [
                            {
                                name: '成交额',
                                type: 'bar',
                                tooltip: {
                                    valueFormatter: function (value) {
                                        return value;
                                    }
                                },
                                data: data1
                            },
                            {
                                name: '招采项目数',
                                type: 'line',
                                yAxisIndex: 1,
                                tooltip: {
                                    valueFormatter: function (value) {
                                        return value;
                                    }
                                },
                                data: data2
                            }
                        ]
                    };
                    // 使用刚指定的配置项和数据显示图表。
                    myChart.setOption(options);
                }
            })
        },
        setAxisLabel(value) {
            if (Math.abs(value) < 10000) {
                return value;
            } else if (Math.abs(value) < 100000000) {
                return (value / 10000).toFixed(1) + "万";
            } else if (Math.abs(value) < 1000000000000) {
                return (value / 100000000).toFixed(1) + "亿";
            } else {
                return (value / 1000000000000).toFixed(1) + "万亿";
            }
        },
        tabsChangge(e) {
            this.getProjectInfoByBidTypeData(e.name);
        },
        xmzlChange(e) {
            this.getProjectOverviewData(e);
        },
        // 物资、工程、服务切换
        getProjectInfoByBidTypeData(bidType) {
            let query = { bidType: bidType, agentId: this.$route.query.agentId };
            this.isLoadingzj = true;
            queryProjectInfoByBidType(this.member, query).then(res => {
                this.isLoadingzj = false;
                if (res.status == 200) {
                    let temdetail = [
                        { name: "预算金额", value: this.setNumber(res.data.finishBudgetAmountPlat), nuit: this.setNumber(res.data.finishBudgetAmountPlat, true), bcolor: '#E6A23C' },
                        { name: "成交金额", value: this.setNumber(res.data.totalAwardPrice), nuit: this.setNumber(res.data.totalAwardPrice, true), bcolor: '#F56C6C' },
                        { name: "降成本", value: this.setNumber(res.data.lower), nuit: this.setNumber(res.data.lower, true), bcolor: '#8dc63f' },
                        { name: "进行中", value: this.setNumber(res.data.progress), nuit: "个", bcolor: '#6739b6' },
                        { name: "已完结", value: this.setNumber(res.data.finished), nuit: "个", bcolor: '#8799a3' },
                        { name: "已流标", value: this.setNumber(res.data.projectEnd), nuit: "个", bcolor: '#1cbbb4' },
                        { name: "参与供应商", value: this.setNumber(res.data.sendSupplier), nuit: "个", bcolor: '#9c26b0' },
                    ]
                    this.detail = temdetail;
                }
            }
            )
        },
        // 项目总览
        getProjectOverviewData(time) {
            let query = { agentId: this.$route.query.agentId };
            if (time && time.length == 2) {
                query.startTime = moment(time[0].getTime()).format("YYYY-MM-DD HH:mm:ss");
                query.endTime = moment(time[1].getTime()).format("YYYY-MM-DD HH:mm:ss");
            }
            this.isLoadingzl = true;
            queryProjectOverview(this.member, query).then(res => {
                this.isLoadingzl = false;
                if (res.status == 200) {
                    let temProject = [
                        { name: "发布项目", value: res.data.publishProject, nuit: "个", icon: 'el-icon-tickets', bcolor: '#E6A23C' },
                        { name: "成交项目", value: res.data.finishProject, nuit: "个", icon: 'el-icon-present', bcolor: '#F56C6C' },
                        { name: "预算总金额", value: this.setNumber(res.data.budgetAmount), nuit: this.setNumber(res.data.budgetAmount, true), icon: 'el-icon-coin', bcolor: '#8dc63f' },
                        { name: "成交总金额", value: this.setNumber(res.data.totalAwardPrice), nuit: this.setNumber(res.data.totalAwardPrice, true), icon: 'el-icon-aim', bcolor: '#6739b6' },
                        { name: "降成本", value: this.setNumber(res.data.lower), nuit: this.setNumber(res.data.lower, true), icon: 'el-icon-sold-out', bcolor: '#8799a3' }
                    ]
                    this.project = temProject;
                }
            })
        },
        setNumber(value, isUnit = false) {
            if (isUnit) {
                if (Math.abs(value) < 10000) {
                    return "元";
                } else if (Math.abs(value) < 100000000) {
                    return "万元";
                } else if (Math.abs(value) < 1000000000000) {
                    return "亿元";
                } else {
                    return "万亿";
                }
            } else {
                if (Math.abs(value) < 10000) {
                    return value;
                } else if (Math.abs(value) < 100000000) {
                    return (value / 10000).toFixed(2);
                } else if (Math.abs(value) < 1000000000000) {
                    return (value / 100000000).toFixed(2);
                } else {
                    return (value / 1000000000000).toFixed(2);
                }
            }
        },
        colorRgb(colorStr) {
            //十六进制颜色值的正则表达式
            var reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/;
            var sColor = colorStr.toLowerCase();
            if (sColor && reg.test(sColor)) {
                if (sColor.length === 4) {
                    var sColorNew = "#";
                    for (var i = 1; i < 4; i += 1) {
                        sColorNew += sColor.slice(i, i + 1).concat(sColor.slice(i, i + 1));
                    }
                    sColor = sColorNew;
                }
                //处理六位的颜色值f
                var sColorChange = [];
                for (var i = 1; i < 7; i += 2) {
                    sColorChange.push(parseInt("0x" + sColor.slice(i, i + 2)));
                }
                return "rgb(" + sColorChange.join(" ") + ' / 10%' + ")";
            } else {
                return sColor;
            }
        }
    }
}
</script>
<style lang="scss" scoped>
$min-height: 1200px;
$screen-width: 1920px; //屏幕宽度
$screen-height: 937px; //屏幕高度
$screen-gap: 10px; //组件间距
$screen-padding: 20px; //组件内边距
$app-min-width: calc(#{$screen-width} / 2 - #{$screen-gap} - 2 * #{$screen-padding}); //组件间距

@mixin display_column {
    display: flex;
    flex-direction: column;
}

.project_c_l_right {
    background-color: #FFF;
    border-radius: 4px;
    border: 1px solid #DCDFE6;
    box-sizing: border-box;
    color: #606266;
    padding: 5px 5px 5px 15px;
    display: flex
}

::v-deep .el-icon-arrow-down {
    display: flex;
    align-items: center;
    color: #C0C4CC;
}

.report-procurement {
    @include display_column;
    height: auto;
    width: $screen-width;
    gap: $screen-gap;
    padding: $screen-padding;
    margin: auto;
}

.clearfix {
    display: flex;

    span {
        flex: 1;
    }
}

.row02,
.row01 {
    display: flex;
    gap: $screen-gap;
    flex: 1;
}

.row02>div,
.row01>div {
    height: calc((#{$screen-height} / 2) - (#{$screen-gap} / 2) - #{$screen-padding});
}

.row01-line01 {
    @include display_column;
    gap: $screen-gap;
}

.row02-line01>div {
    flex: 1;
}

.row02-line02,
.row02-line01,
.row01-line02,
.row01-line01 {
    flex: 1;
}

.row02-line01 {
    display: flex;
    gap: $screen-gap;
}

::v-deep .el-dialog__header {
    display: none;
}

@media screen and (min-width: $min-height) {

    .row02-line02,
    .row02-line01,
    .row01-line02,
    .row01-line01 {
        min-width: $app-min-width;
    }
}

@media screen and (max-width: $min-height) {
    .report-procurement {
        width: 100vw;
    }

    .row02-line02,
    .row02-line01,
    .row01-line02,
    .row01-line01 {
        flex: none !important;
        width: $app-min-width;
    }



    .row02,
    .row01 {
        flex-direction: column;
        align-items: center;
    }
}
</style>
<style scoped>
.el-form-item {
    margin-bottom: 0px;
}

.dialog-l-project {
    gap: 20px;
    display: flex;
    flex-direction: column;
}

.project-c-title-right {
    width: 200px;
    word-break: break-all;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: #C0C4CC;
}

.el-card {
    font-size: 14px;
}

.report-procurement>>>.el-skeleton {
    background-color: #fff;
    padding: 10px;
    height: 100%;
}

.procurement-table-body01>>>.el-card__body,
.el-main {
    width: 100% !important;
}

.procurement-table-body02>>>.el-card__body,
.el-main {
    width: 100% !important;
}

.report-procurement>>>.el-card__body,
.el-main {
    width: 925px;
}

.report-procurement-detail>>>.el-tabs__header {
    margin: 0;
}

.report-procurement-detail>>>.el-tabs__nav-wrap::after {
    display: none;
}

.report-procurement-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: red;
    display: flex;
    align-items: center;
    justify-content: center;
    /* opacity: 0.5; */
    font-size: 25px;
    color: #fff;
    margin-right: 10px;
}

.report-procurement-table>>>.el-table--fit {
    height: 356px !important;
}

.report-procurement-pro-card {
    display: flex;
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 5px 10px;
    align-items: center;
    justify-content: center;
    width: 19%;
    height: 80px;
}

.report-procurement-detail-card {
    /* background-color: #ffe0ff; */
    height: 100px;
    width: 110px;
    margin: 0 10px;
    border-radius: 5px;
}

.report-detail-card-head::after {
    content: '';
    width: 80px;
    height: 1px;
    background-color: #fff;
    position: absolute;
    bottom: 0;
}

.report-detail-card-head {
    background-color: rgb(255 224 255 / 50%);
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0 0 10px 10px;
    position: relative;
}

.report-detail-card-body {
    background-color: rgb(255 224 255 / 50%);
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px 10px 0 0;
}

.report-procurement>>>.el-col-12,
.report-procurement>>>.grid-content,
.report-procurement>>>.el-card {
    height: 100% !important;
    width: 100% !important;
}
</style>